.bid-rgisration-new-modal .modal-dialog {
  width: 630px;
  min-width: 630px;
}
.login-new-modal .modal-dialog {
  width: 464px;
}
.login-modal-footer.modal-footer {
  justify-content: space-between;
}
.login-modal-footer .btn {
  min-width: 132px;
  min-height: 56px;
  font-family: "BentonSansMedium";
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-transform: uppercase;
}

.not-now-btn,
.login-butn,
.signup-btn {
  background: #fff !important;
  color: #000 !important;
  border-color: #000 !important;
}
.not-now-btn:hover,
.login-butn:hover,
.signup-btn:hover {
  background: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
}
.login-modal-text {
  font-family: "BentonSansRegular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.modal-header {
  font-family: "MercuryDisplayBold";
  font-size: 20px;
  font-weight: 700;
  line-height: 23.34px;
}
.login-new-modal .modal-header svg {
  cursor: pointer;
}

@media (max-width: 767px) {
  .login-new-modal .modal-dialog {
    width: 96%;
  }
  .login-modal-footer .btn {
    min-width: 100%;
    min-height: 40px;
    font-size: 14px;
  }
  .bid-rgisration-new-modal .modal-dialog {
    width: 96%;
    min-width: auto;
  }
}
