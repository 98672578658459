.faq_page_content.support_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="support"] {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;

  background: #000;
  color: #fff;
  border-radius: 4px;
}

.faq_page_content.support_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="support"]:hover {
  background: transparent;
}

.faq_page_content.support_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="faq"] {
  color: #000;
  font-weight: 600;

  background: #000;
  color: #fff;
  border-radius: 4px;
}

.faq_page_content.support_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="faq"]:hover {
  /* background: #fff; */
}
