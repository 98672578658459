.description_tabs_content {
  padding-top: 30px;
}
.description_tabs_content .prd_des_list {
  width: 70%;
  border-bottom: 1px solid #c4c4c4;
}

.description_tabs_content .prd_des_list .nav-item {
  margin-left: 50px;
  border: 0;
  font-family: "Noto Serif", serif;
  font-style: normal;
  color: #333333;
  font-weight: 600;
  font-size: 18px;
  display: block;
  padding: 0;
  padding-bottom: 10px;
  line-height: 25px;
  position: relative;
  opacity: 0.4;
  background: transparent;
}

.description_tabs_content .prd_des_list .nav-item.active::before {
  content: "";
  background: #212121;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.description_tabs_content .prd_des_list .nav-item.active {
  opacity: 1;
}

.description_tabs_content .tab-content {
  padding: 20px 50px;
  font-family: "Noto Serif", serif;
}

.description_tabs_content .tab-content .des_tab_cont p {
  padding-bottom: 25px;
  color: #333333;
}

.description_tabs_content .tab-content .des_tab_cont h4 {
  font-size: 20px;
  color: #333333;
  font-weight: 400;
}

.des_tab_cont .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
.tabs-content-section-view-main {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #111111;
  /* margin-bottom: 30px; */
}
.tabs-content-section-view {
  margin-bottom: 20px;
  margin-top: 48px;

  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #111111;
}
.other-items-list-by-sec > li > div {
  display: flex;
  line-height: 35px;
}
.other-items-list-by-sec .other-item-key-val {
  width: 170px;
  min-width: 170px;
}
.other-items-list-by-sec .other-item-collen-val {
  width: 50px;
  min-width: 50px;

  text-align: center;
}
/* media query css start */
.lot-details-1 .tabs-content-section-view {
  font-family: "BentonSansMedium";
  font-size: 18px;
  font-weight: 400;
  line-height: 18.18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.lot-sticky-sec {
  display: grid;
  grid-template-columns: 15% 83%;
  gap: 2%;
}
.lot-page-description span {
  font-family: "BentonSansRegular";
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}

.lot-page-description .public-DraftStyleDefault-block {
  margin-bottom: 16px;
}
.lot-sticky-sec .profile-tabs.sticky {
  height: auto;
}
.lot-sticky-sec .profile-tabs {
  height: 100%;
  padding-top: 48px;
}
.lot-sticky-sec .ant-tabs-content-holder {
  display: none;
}
.lot-sticky-sec .ant-tabs-tab .ant-tabs-tab-btn {
  border-left: 1px solid #d8d8d8;
  margin: 0px !important;
  font-family: "BentonSansRegular";
  font-size: 12px !important;
  font-weight: 400;
  line-height: 12.12px;
  color: #111111;
}
.lot-sticky-sec .ant-tabs-tab .ant-tabs-tab-btn {
  color: #000 !important;
}
.lot-sticky-sec .ant-tabs-ink-bar {
  background: #000;
}
.lot-sticky-sec .ant-tabs-tab {
  padding: 0px !important;
  margin-top: 0px !important;
}
.lot-sticky-sec .ant-tabs-tab .ant-tabs-tab-btn {
  padding: 12px 24px;
}

@media (max-width: 991px) {
  .description_tabs_content .tab-content {
    padding: 20px 15px;
  }
  .description_tabs_content .prd_des_list {
    width: 100%;
  }
  .description_tabs_content .prd_des_list .nav-item {
    margin-left: 32px;
  }
  .description_tabs_content .prd_des_list .nav-item:first-child {
    margin-left: 15px;
  }
  .lot-sticky-sec {
    display: flex;
  }
}
@media (min-width: 1250px) {
  .lot-sticky-sec {
    grid-template-columns: 22% 73%;
  }
}

@media (max-width: 600px) {
  .description_tabs_content .prd_des_list .nav-item {
    font-weight: 500;
    font-size: 14px;
  }
  .description_tabs_content .tab-content .des_tab_cont p {
    font-size: 14px;
  }
  .other-items-list-by-sec > li {
    margin-bottom: 10px;
    display: block;
  }
  .other-items-list-by-sec > li > div {
    display: block;
  }
  .other-items-list-by-sec .other-item-collen-val {
    display: none;
  }
  .other-items-list-by-sec .other-item-key-val {
    font-weight: bold;
  }
  .other-items-list-by-sec .other-item-key-val span {
    display: inline-block !important;
  }
  .other-items-list-by-sec .other-item-collen-val-val {
    line-height: 25px;
  }
  .other-items-list-by-sec {
    padding-left: 10px;
  }
}

/* media query css end */
