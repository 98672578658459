/* .bids_component_section{
    margin-bottom: 107px;
} */

.bids_left_component .bids_lots_text {
  padding: 5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  font-family: benton-sans, sans-serif;
}

.bids_left_component .bids_lots_heading {
  font-weight: 325;
  font-size: 20px;
  font-family: "Mercury Display A, Mercury Display B, MercuryDisplay-wb, Mercury Display",
    serif;
  color: #fff;
  text-transform: uppercase;
}
.place-bid-time-sec-count {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;

  padding: 1rem;
  padding-top: 6px;
}
.current-bid-sec-1-sec {
  margin-bottom: 20px !important;
}
.place-bid-time-sec-count .full-time-sec-amount-sec-count .time-red-color {
  font-size: 16px;
}
.place-bid-time-sec-count .place-bid-time-sec-count-time p {
  font-size: 16px !important;
}
.place-bid-time-sec-count .place-bid-time-sec-count-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.place-bid-time-sec-count p {
  margin-bottom: 0px;
}
.place-bid-time-sec-count .lot-begin-text {
  font-family: "BentonSansLight";
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  display: inline-block;
}
.lot-closes-time {
  font-size: 14px;
  font-weight: 300;
  font-family: "BentonSansLight";
  color: rgb(51, 51, 51);
}
.place-bid-time-sec-count .full-time-sec-amount-sec-count {
  display: inline-block !important;
}
.currency-top-section {
  display: flex;
  justify-content: space-between;
}
.bids_left_component .bids_lots_heading-top {
  color: #000;
}
.bids_left_component .bids_lots_close_estimate_text_content,
.bids_left_component .bids_lots_heading {
  width: calc(100% - 15%);
}

.bids_left_component
  .bids_lots_close_estimate_text_content
  .bids_lots_close_estimate_text
  .bids_lots_close_estimate_label {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "BentonSansSmall" !important;
  font-weight: 500;
  text-transform: uppercase;
}

.bids_left_component
  .bids_lots_close_estimate_text_content
  .bids_lots_close_estimate_text
  .bids_lots_close_estimate_peregraph {
  font-size: 16px;
  color: #000000;
  font-family: "BentonSansRegular";
  font-weight: 400;
  text-transform: uppercase;
}

.bids_left_component
  .bids_lots_close_estimate_text_content
  .bids_lots_close_estimate_text.bids_lots_estimate_text
  .bids_lots_close_estimate_peregraph {
  font-weight: 400;
}

.bids_right_main_component {
  background: #222;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-left: 0;
  border-right: 0;
}

.bids_right_main_component::before {
  content: "";
  position: absolute;
  left: -90px;
  top: 0px;
  width: 90px;
  height: 100%;
  background: #222;
  z-index: -1;
}
.my-active-bids-count-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.my-active-bids-count-btn button {
  height: 50px;
  border: none;
  border-radius: 4px;
  padding: 5px 20px;
  line-height: 20px;
  font-size: 16px;
}

.my-active-bids-count-div {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}
.my-active-bids-count-div .active-bid-button {
  height: 40px;
  border: none;
  border-radius: 4px;
  padding: 5px 20px;
  line-height: 20px;
  font-size: 16px;
  width: 95%;
  background-color: black;
  border: 1px solid white;
  margin: 0px 2px;
}
.my-active-bids-count-div .active-bid-button:active,
.my-active-bids-count-div .active-bid-button:hover {
  background-color: #000 !important;
  box-shadow: none !important;
  border: 1px solid white !important;
}
.my-active-bids-count-div .button-popover {
  border: none;
  background: none;
  margin-top: 5px;
}

.your-max-bid-amount-text {
  /* margin-top: 20px; */
  /* text-align: center; */
  color: #0aee8e;
  /* margin: auto; */
  /* margin-top: 20px; */
  /* padding: 0% 10%; */
  margin-top: 24px;
  margin-bottom: 24px;
}
.disable-quick-bid-text {
  margin-top: 20px;
  text-align: center;
  color: #0aee8e;
  margin: auto;
  margin-top: 20px;
  padding: 0% 10%;
  font-size: 16px;
  font-family: "BentonSansRegular";
}
.my-active-bids-count-btn {
  cursor: pointer;
}
.my-active-bids-count-btn a {
  color: #fff;
  padding: 8px 32px;
  border: 1px solid #fff;
  width: 100%;
  display: block;
  text-decoration: none;
  font-size: 16px;
}
.all_pages_back_btn_top span {
  margin-right: 30px;
}

.bids_right_main_component::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0px;
  /* width: 500%; */
  height: 100%;
  background: #222;
  z-index: -1;
}

.onHover:focus,
.onHover:active {
  background: #333 !important;
  border: none !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 8%) inset !important;
}

.btnHover:hover {
  background: #333 !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 8%) inset !important;
}

.bids_right_component {
  max-width: 89%;
  margin: 0 auto;
  padding: 77px 0px 230px;
}
.bids_right_component .lot-begin-date {
  margin-bottom: 0px !important;
  text-transform: uppercase;
}
.bids_right_component .lot-begin-text {
  margin-bottom: 8px !important;
}
.place-bid-green-text.green-color-amount-text {
  margin-left: 114px;
  margin-top: -10px;
  display: block;
  margin-bottom: 24px !important;
  font-size: 20px;
}
.place-bid-green-text.green-color-amount-text span {
  font-size: 20px;
}
.place-bid-estimate-color-text.green-color-amount-text,
.place-bid-estimate-color-text.green-color-amount-text span {
  font-size: 16px;
  line-height: 22px;
}
.green-color-amount-text.place-bid-scces-msg {
  display: inline;
  font-weight: 600;
}
.bids_right_component .reverse_time_text {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.bids_right_component .reverse_time_text span {
  font-weight: 500;
}

.bids_enter_content .bids_enter_heading {
  font-weight: 400;
  color: #fff;
  font-family: "BentonSansRegular" !important;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.bids_enter_content .bids_enter_peregraph {
  font-size: 18px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.74);
  font-family: "BentonSansRegular", sans-serif;
}

.bids_enter_content .bids_enter_current_bid_text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.74);
  font-family: "BentonSansRegular" !important;
  display: flex;
  align-items: center;
  font-weight: 400;
  text-transform: uppercase;
}
.place-bid-max-sec {
  padding: 20px;
  border: 1px solid #fffce033;
  background: #131313;
  border-radius: 16px;
}
.place-bid-current-sec {
  font-family: "BentonSansLight" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.bids_enter_content .bids_enter_current_bid_amount {
  color: #fff;
  font-family: "BentonSansRegular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bids_enter_content .custom_bid_select_box {
  background: url(../../../assets/image/white-arrow-down.svg) no-repeat top 20px
    right 25px #222;
  font-size: 20px;
  border: 1px solid #fff !important;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #fff;
  outline: 0 !important;
  width: 100%;
  height: 62px;
  background-color: transparent;
  font-family: "BentonSansRegular", sans-serif;
}

.bids_enter_content .custom_bid_select_box:focus {
  box-shadow: none;
}

.bids_enter_content .bids_enter_currency_text {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.32px;
  margin: 25px 0;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.bids_enter_content .bids_enter_currency_text a {
  color: #333333;
}

.bids_enter_content .bids_enter_btn_lots .bids_enter_lots_btn_same {
  border: 1px solid #333333;
  background: #fff;
  color: #000;
  width: 100%;
  font-size: 16px;
  height: 56px;
  line-height: 56px;
  margin: 0 2px;
  border-radius: 4px;
  padding: 0 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  box-shadow: unset !important;
}

.bids_enter_content .bids_enter_btn_lots span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  margin: 16px 0;
  display: block;
}

.bids_enter_content .bids_enter_btn_lots .bids_enter_lots_btn_same.bg-white {
  color: #000;
}

.bids_enter_content .enter_bid_works {
  margin-top: 30px;
}

.bids_enter_content .enter_bid_works a {
  font-size: 14px;
  margin-bottom: 15px;
  color: #000000;
}

.bids_left_component .carousel-fade .carousel-item {
  height: 585px;
}

.bids_left_component .carousel-fade .carousel-item img {
  object-fit: contain;
  /* object-fit: fill; */
  border-top: 13px solid #f5f5f5;
  border-bottom: 13px solid #f5f5f5;
  border-left: 89px solid #f5f5f5;
  border-right: 89px solid #f5f5f5;
  height: 100%;
}

.bids_left_component .carousel-fade .carousel-indicators li {
  width: 16px;
  height: 6px;
  background: #c4c4c4;
  border-radius: 6px;
  border: 0;
}
.text-center-or-text {
  color: #fff;
}
.bids_left_component .carousel-fade .carousel-indicators li.active {
  background: #767676;
}

.bids_left_component .carousel-fade .carousel-indicators {
  bottom: -18px;
  margin-bottom: 0;
  display: none;
}

.bids_left_component .carousel-fade .carousel-item div {
  width: 100%;
}
.curouselImageDiv {
  min-width: 683px !important;
  min-height: 487px !important;
  object-fit: cover !important;
  display: contents !important;
}

.bids_component_section .bids_left_component .carousel {
  width: 105%;
}
.bidding-table-data-sec {
  display: flex;
  justify-content: space-around;
}

.mobile-bidding-table-data-sec {
  display: inline;
  justify-content: left;
}
.mobile-bidding-table-data-sec p {
  text-align: left;
}
.bidding-table-data-sec span {
  color: rgba(255, 255, 255, 0.74);
  width: 1px;
  height: 23px;
  background: rgba(255, 255, 255, 0.74);
}
.bidWorksText {
  font-family: "BentonSansRegular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #fff;
  text-align: right;
  cursor: pointer;
  text-transform: uppercase;
}
.bids_enter_currency_text {
  color: #fff;
}
.custom_bid_select_box option {
  color: #000;
}
.bids_right_component .bids_enter_lots_btn_same:hover,
.bids_right_component .bids_enter_lots_btn_same:active {
  background: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
}
.card-box-top-right-new {
  color: #ef9b29;
  font-family: "BentonSansRegular";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.lot-page.card-box-top-right-new {
  font-family: "BentonSansMedium";
  font-size: 20px;
}
.place-bid.card-box-top-right-new {
  font-family: "BentonSansMedium";
  font-size: 20px;
}
.green-colo-new {
  color: #50ae00;
}

.bids_enter_btn_lots .mobile-confirm-to-place-quickbid span {
  line-height: normal !important;
  margin: 1px 0px 1px 0px !important;
}
.mobile-confirm-to-place-quickbid {
  height: 60px !important;
}

.bids_enter_content .bids_enter_current_bid_amount {
  margin: 3px 4px 0px 4px;
}
.active-bids-fixed-sec {
  position: fixed;
  bottom: 24px;
  z-index: 9999;
  color: var(--Shade-0, #fff);
  font-family: BentonSansMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
  text-transform: uppercase;
  background: black;
  padding: 12px;
  border-radius: 24px 24px 24px 0px;
  background: #000;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.custom_bid_select_box.custom-select {
  transition: none !important;
}

/* media query css start */
@media (min-width: 991px) {
  .bids_left_component .carousel-fade .carousel-item {
    /* min-width: 683px;
        min-height: 487px; */
  }
}

@media (max-width: 1440px) {
  /* .bid_two_page_content{
        overflow-x: hidden;
    } */
  .bids_left_component .bids_lots_heading {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .bids_left_component
    .bids_lots_close_estimate_text_content
    .bids_lots_close_estimate_text
    .bids_lots_close_estimate_label,
  .bids_enter_content .bids_enter_currency_text a,
  .bids_enter_content .bids_enter_btn_lots .bids_enter_lots_btn_same {
    font-size: 16px;
  }
  .bids_left_component
    .bids_lots_close_estimate_text_content
    .bids_lots_close_estimate_text
    .bids_lots_close_estimate_peregraph {
    font-size: 16px;
  }
  .bids_enter_content .bids_enter_heading {
    font-size: 28px;
  }
  .bids_enter_content .bids_enter_current_bid_text {
    font-size: 16px;
    line-height: 34px;
  }
  .bids_enter_content .bids_enter_peregraph {
    font-size: 16px;
    line-height: 26px;
  }
  .bids_right_component {
    width: 100%;
    color: #fff;
  }
  .bids_enter_content .bids_enter_current_bid_amount {
    font-size: 20px;
  }
  .bids_enter_content .custom_bid_select_box {
    font-size: 20px;
    height: 58px;
  }
  .bids_enter_content .bids_enter_currency_text {
    margin: 20px 0;
  }
  .bids_enter_content .bids_enter_btn_lots span {
    font-size: 16px;
  }
  .bids_enter_content .enter_bid_works a {
    font-size: 13px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1366px) {
  .bids_right_component .reverse_time_text {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .bids_right_component {
    padding: 77px 0px 100px;
    min-height: 900px;
  }
}

@media (max-width: 991px) {
  .bids_left_component .carousel-fade .carousel-indicators {
    display: flex;
  }
  .bids_right_main_component::before,
  .bids_right_main_component::after {
    display: none;
  }
  .bids_right_main_component {
    margin-top: 50px;
    background: #000;
    border: 0;
    margin-bottom: 100px;
    padding: 0px 10px;
  }
  .bids_left_component .bids_right_component {
    display: block !important;
    max-width: 100%;
    text-align: center;
  }
  .bids_left_component .bids_lots_close_estimate_text_content,
  .bids_left_component .bids_lots_heading,
  .bids_component_section .bids_left_component .carousel {
    width: 100%;
  }
  .bids_lots_close_estimate_text_content {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .bids_left_component .carousel-fade .carousel-item,
  .bids_left_component .carousel-fade .carousel-item img {
    height: 343px;
  }
  .bids_right_component .reverse_time_text {
    margin-bottom: 32px;
    padding: 13px 5px !important;
    font-size: 20px;
  }
  .bids_right_component {
    padding-top: 30px;
    padding-bottom: 0;
    max-width: 100%;
  }
  .bids_enter_content .bids_enter_currency_text {
    font-weight: 400;
  }
  .bids_component_section {
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .bids_left_component .carousel-fade .carousel-item img {
    border-left: 30px solid #f5f5f5;
    border-right: 30px solid #f5f5f5;
  }

  .bids_enter_content .custom_bid_select_box {
    font-size: 16px;
  }
  .bids_left_component .bids_lots_heading {
    font-size: 20px;
    padding-top: 2px !important;
  }
  .bids_left_component
    .bids_lots_close_estimate_text_content
    .bids_lots_close_estimate_text
    .bids_lots_close_estimate_peregraph,
  .bids_left_component
    .bids_lots_close_estimate_text_content
    .bids_lots_close_estimate_text
    .bids_lots_close_estimate_label,
  .bids_enter_content .bids_enter_current_bid_text,
  .bids_enter_content .bids_enter_current_bid_amount {
    font-size: 12px;
  }
  .bids_enter_content .bids_enter_current_bid_amount {
    font-size: 12px;
  }
  .bids_enter_content .custom_bid_select_box {
    height: 56px;
  }
  .bids_enter_content .bids_enter_btn_lots .bids_enter_lots_btn_same {
    font-size: 14px;
    height: 41px;
    line-height: 41px;
  }

  .bids_enter_content .bids_enter_btn_lots .bids_enter_lots_btn_same img {
    width: 18px;
  }
  .bids_enter_content .bids_enter_btn_lots span {
    font-size: 13px;
    margin: 10px 0;
  }
  .bids_enter_content .enter_bid_works a {
    font-size: 13px;
    margin-bottom: 10px !important;
    display: block;
  }
  .bids_enter_content .bids_enter_currency_text {
    margin-bottom: 0 !important;
  }
  .timer-view-place-bid .reverse_time_text {
    font-size: 17px;
  }
  .place-bid-green-text.green-color-amount-text {
    margin-left: 70px;
    font-size: 12px;
    margin-top: -16px;
  }
  .place-bid-green-text.green-color-amount-text span {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  /* .bids_right_component .reverse_time_text {
    font-size: 20px;
  } */
  .bids_enter_content .custom_bid_select_box {
    height: 56px;
  }
  .currency-top-section {
    display: block;
  }
  .all_pages_back_btn_top {
    width: 100%;
  }
  .place-bid-current-sec {
    font-size: 10px;
    line-height: 22px;
  }
}

@media (max-width: 370px) {
  .bids_right_component .reverse_time_text {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .bidding-table-data-sec p {
    font-size: 12px;
  }
}
@media (min-width: 1401px) {
  .bids_left_component
    .bids_lots_close_text
    .bids_lots_close_estimate_peregraph {
    font-size: 16px;
  }
}
@media (min-width: 1131px) and (max-width: 1250px) {
  .bids_left_component
    .bids_lots_close_text
    .bids_lots_close_estimate_peregraph {
    font-size: 13px !important;
  }
}
@media (min-width: 991px) and (max-width: 1130px) {
  .bids_left_component
    .bids_lots_close_text
    .bids_lots_close_estimate_peregraph {
    font-size: 11px !important;
  }
}

/* media query css end */
