.loadMore {
  margin-top: 40px;
}
.dateLeft {
  margin-left: -58px;
  margin-right: -44px;
  font-family: "BentonSansRegular";
  font-weight: normal;
  color: #000;
}
.mt-auto-top {
  margin-top: 25px;
}
.dash-line-auction-aid {
  display: inline-block;
  margin: 0px 8px;
}
.auction-aid-text {
  color: #111;
  font-family: "BentonSansRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.sale-amount-text {
  font-family: "BentonSansLight";
  font-size: 12px;
  font-weight: 400;
  line-height: 12.12px;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
}
.mobile-calender-il {
  padding-left: 0px;
  text-transform: uppercase;
}
.calender-month-text {
  margin-left: -17px;
  margin-left: -21px;
  margin-right: -32px;
  display: inline-block;
  display: inline !important;
  color: #19191d;
  font-family: "BentonSansMedium" !important;
  font-size: 16px;
}
.mobile-date-text {
  font-family: "BentonSansRegular";
  font-size: 12px;
}
.green-color-amount-text span,
.green-color-amount-text {
  font-size: 12px;
  font-family: "BentonSansRegular";
  color: rgb(107, 107, 107) !important;
  line-height: 16px;
}
.green-color-amount-text.calender-text-green-text {
  margin-left: 70px;
}
@media (max-width: 767px) {
  .forMobileViewstyle {
    margin-top: -20px !important;
  }
}
.state-date-time-boldfalse {
  font-family: "BentonSansMedium" !important;
}
.calender-bottom-sec {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #333;
  padding-top: 24px;
  margin: 16px 0px 0px 20px;
}
.left-side-callender svg {
  transform: rotate(180deg);
}
.calender-bottom-sec h6 {
  cursor: pointer;
}
