.change-password-modal .modal-dialog {
  width: 630px;
  min-width: 630px;
}
.change-password-modal .modal-content {
  padding: 0px;
}
.change-password-modal .modal-content .modal-body {
  padding: 24px;
}
.change-password-modal .change-password-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #dbdbdb;
}
.change-password-header h3 {
  margin-bottom: 0px;
  font-family: "BentonSansBold";
  font-size: 20px;
  font-weight: 500;
  line-height: 27.24px;
  text-align: left;
}
.change-password-header svg {
  cursor: pointer;
}
.change-password-modal .password-input-sec {
  margin-top: 16px;
}
.password-input-sec label {
  font-family: "BentonSansRegular";
  font-size: 14px;
  font-weight: 400;
  line-height: 14.14px;
  color: #111111;
}
.password-input-sec input {
  padding: 14px 15px;
  height: 44px;
}
.input-group-btn {
  top: -43px;
}
.change-password-buttons {
  justify-content: end;
  gap: 16px;
}
.change-password-buttons .close_modal_btn {
  font-family: "BentonSansMedium";
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  min-width: 136px;
  background: #ffffff;
  border-color: #111111;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.change-password-buttons .close_modal_btn:hover {
  background: #000;
  color: #ffffff;
}
.change-password-buttons .save_change_modal_btn {
  font-family: "BentonSansMedium";
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  min-width: 136px;
  background: #000;
  border-color: #111111;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
@media (max-width: 767px) {
  .change-password-modal .modal-dialog {
    width: 95%;
    min-width: 95%;
  }
  .change-password-buttons .close_modal_btn,
  .change-password-buttons .save_change_modal_btn {
    width: 100%;
    min-width: auto;
  }
}
