.action_calendar_page_section {
  padding: 50px 0;
}

.noResults {
  padding: 2px 4px 5px 62px;
  font-size: 20px;
}
.noResults-auctionpafw {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 24px;
}
.noResults-auctionpafw span {
  font-size: 16px;
  display: block;
}
.auction-calender-tabs {
  .nav-item {
    border: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.4399999976158142px;
    text-align: left;
    color: #19191d;
  }
  .nav-item.active {
    border-bottom: 4px solid #19191d;
    color: #19191d;
  }
}
.sticky-calender-filter-sec {
  width: 480px;
  position: sticky;
  top: 10px;
  /* width: 140px; */
  height: 600px;
}

@media (max-width: 1366px) {
  .action_calendar_page_content .container {
    width: 1140px;
  }
}

@media (max-width: 1171px) {
  .action_calendar_page_content .container {
    width: 98%;
  }
}

@media (max-width: 767px) {
  .action_calendar_page_section {
    padding: 30px 0;
  }
  .noResults-auctionpafw {
    text-align: center;

    padding: 0;
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 200px;
  }
}

@media (max-width: 375px) {
  .action_calendar_page_content .container {
    width: 100%;
  }
}
.results-search-box.searchBox {
  position: absolute;
  top: 45px;
  right: 12px;
  width: 388px;
}
.results-search-box.searchBox:active {
  box-shadow: none;
  outline: none;
}

.results-search-box.searchBox input {
  border-color: #19191d;
  box-shadow: none;
  padding: 8px 42px 8px 8px;
}

.calender-search-box {
  position: absolute;
  right: 13px;
  top: 160px;
  width: 388px;
  z-index: 999;
}
.calender-search-box.searchBox input {
  padding: 8px 42px 8px 8px;
}

.calender-search-box.searchBox .searchBoxTextField:active {
  box-shadow: none;
  outline: none;
}
.calender-search-box.searchBox .searchBoxTextField {
  border-color: #19191d;
  box-shadow: none;
}

@media (max-width: 767px) {
  .calender-search-box {
    position: absolute;
    right: 0px;
    top: 122px;
    width: 100% !important;
    width: 388px;
    height: 35px !important;
    z-index: 999;
    padding: 0px 14px;
  }
  .results-text {
    margin-top: 16px;
  }
  .auction-results-page.results-text {
    margin-top: 64px;
  }
  .results-search-box.searchBox {
    position: absolute;
    top: 122px;
    right: 0px;
    width: 100% !important;
    padding: 0px 14px;
    z-index: 999;
  }
  .auction-calender-tabs.calender-subtabs {
    display: flex;
    margin-top: 72px !important;
    justify-content: space-between;
  }
  .auction-calender-tabs.calender-subtabs .nav-item {
    font-size: 14px;
  }
}

.action_calendar_page_section .container .calender-bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 4px;
  height: 30px;
  background: #fdd490;
  transform: rotate(15deg);
}
