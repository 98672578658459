.browes_tab_right_tab .browes_tab_content .filter_list {
  width: 280px;
}
.spinner_Loading_iconCollectionDetails svg {
  width: 100%;
  height: 1000px;
  margin-top: -08px;
}
.browes_tab_right_tab .browes_tab_content .filter_list_data_main {
  width: calc(100%);
  padding-left: 45px;
}
.filter_list_sort_section {
  gap: 20px;
}
.filter_list_sort_section .searchBox {
  width: 464px;
  font-family: benton-sans, sans-serif;
}
.filter_list_sort_section_sory_by {
  width: 60px;
  position: absolute;
  top: 12.5px;
  font-size: 14px;
  left: 12px;
  font-family: benton-sans, sans-serif;
}
.collection-title-sec-overview p {
  font-weight: bold;
}
.browes_tab_right_tab
  .browes_tab_content
  .filter_list_data_main
  .custom_select_dropdown {
  border: none !important;
  width: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 40px;
  padding-right: 10px;
  font-weight: 600;
  padding-left: 54px;
}
.action_calenda_filter_menu .clearAllStyles {
  font-family: benton-sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.375rem;
  color: rgb(51, 51, 51);
  margin-bottom: 12px;
  margin-left: 20px;
  background: #fff;
  padding: 12px;
  color: #333;
  border: 1px solid #333;
  border-radius: 4px;
  text-transform: uppercase;
}
.action_calenda_filter_menu .clearAllStyles:hover {
  color: #fff;
  background: #333;
}
.reserve-price-filter-list {
  padding-left: 12px;
}
.mobile_right_sidebar_content button span {
  font-family: "BentonSansRegular";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.5rem;
}
.mobile_right_sidebar_content button .sales-heading {
  font-size: 16px;
}
/* .mobile_right_sidebar_content button .selected-year-span {
  font-size: 16px;
  font-family: "BentonSansRegular";
  font-weight: 600;
} */
.filter-sec-new-version {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #333;
  font-family: benton-sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 15px 32px;
  cursor: pointer;
  letter-spacing: 0.015625rem;
  line-height: 1.25rem;
}
.browes_tab_right_tab
  .browes_tab_content
  .filter_list_data_main
  .custom_select_dropdown
  label {
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  width: 63px;
}
.lightColor {
  color: rgba(0, 0, 0, 0.5) !important;
  font-style: normal;
}
.homepage-main-filter {
  height: 100vh;
  background: #fff;
  padding: 10px;
  z-index: 999;
}
.mobile-home-page-filter {
  width: 100% !important;
}
.first_heading_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: 24px;
}
.fl_heading_h1 {
  font-family: Mercury Display A, Mercury Display B, MercuryDisplay-wb,
    Mercury Display, serif;
  font-size: 1.75rem;
  font-weight: 325;
  margin: 0px;
}

.first_heading_wrap svg {
  cursor: pointer;
}
.browes_tab_right_tab
  .browes_tab_content
  .filter_list_data_main
  .custom_select_dropdown
  select {
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  border: 0;
  padding-right: 20px;
  color: #333333;
  outline: 0 !important;
  box-shadow: none;
  width: auto;
  padding-top: 4px;
  font-family: benton-sans, sans-serif;
}
.tab-my-active-bid .button-popover {
  margin-top: -5px;
}

.action_calendar_right_tab .grid_list_tab_menu {
  position: absolute;
  right: 0;
  top: 30px;
}

.filter_list_data_main .filter_list_sort_section {
  padding-right: 84px;
}

.action_calendar_right_tab .grid_list_tab_menu .nav-item {
  margin-left: 20px !important;
  margin-right: 0 !important;
  width: 22px;
  height: 23px;
  padding: 0 !important;
  opacity: 0.2;
}

.action_calendar_right_tab .grid_list_tab_menu .nav-item.active {
  opacity: 1;
}

.action_calendar_right_tab
  .tab-content
  .grid_list_tab_menu.nav-tabs
  .nav-item::before {
  content: "";
  position: absolute;
  left: 0 !important;
  bottom: 0 !important;
  right: 0;
  top: 0;
  height: 100%;
  background-image: url(../../../assets/image/grid_view.png);
  background-color: transparent;
}

.action_calendar_right_tab
  .tab-content
  .grid_list_tab_menu.nav-tabs
  .nav-item:last-child:before {
  background-image: url(../../../assets/image/list-view.png);
}

.action_calendar_right_tab.browes_tab_right_tab .nav-tabs .nav-item {
  min-width: fit-content;
  color: rgb(107, 107, 107);
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 500;
}

.action_calendar_right_tab.browes_tab_right_tab .nav-tabs .nav-item.active {
  opacity: 1;
}

.action_calendar_right_tab.browes_tab_right_tab .nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}

.browes_tab_other_text_tabs {
  padding: 30px 0;
  margin-top: 80px;
}

.browes_tab_other_text_tabs h3 {
  color: #000000;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

.browes_tab_other_text_tabs p {
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  padding-bottom: 10px;
}

.action_calendar_right_tab.browes_tab_right_tab .nav-tabs .nav-item {
  margin: 0 55px;
}
.forAssistText a {
  text-decoration: underline;
}
.auctionNameValues {
  margin-left: 0px !important;
}
.auctionNameValues p {
  display: grid;
  grid-template-columns: 16% 74%;
  height: 30px;
  column-gap: 2%;
}
.auctionDetailsLeft {
  display: flex;
  justify-content: flex-start;
}
.month-uppercase {
  text-transform: uppercase;
}
.filter_list_data_main_overview {
  width: 100%;
  /* margin-top: -30px; */
}
.tab-my-active-bid {
  align-items: center;
}
.coverImage-collection-image {
}
.coverImage-collection-details-text {
  backdrop-filter: blur(12px);
  font-size: 18px;
  font-weight: 400;
  inset: 0px;
  padding: 30px;
  font-family: BentonSans-Regular, "sans-serif";
}
@media (max-width: 991px) {
  .browes_tab_right_tab .browes_tab_content .filter_list_data_main {
    width: 100%;
    padding-left: 0;
  }
  .browes_tab_right_tab .browes_tab_content .filter_list {
    width: calc(100% - 90px);
  }
  .browes_tab_right_tab
    .browes_tab_content
    .filter_list
    .action_calenda_filter_menu
    .filter_heading {
    margin: 18px 0 18px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .action_calendar_right_tab .grid_list_tab_menu {
    top: 21px;
  }
  .header-search-input input {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 9;
  }
  .action_calendar_right_tab.browes_tab_right_tab .nav-tabs .nav-item {
    margin: 0 15px;
  }
}

@media (max-width: 767px) {
  .filter_list_data_main_overview {
    margin-top: 20px;
  }
  .auctionNameValues .lotsNumberSpacing {
    margin-left: 0px !important;
  }
  .browes_tab_right_tab
    .browes_tab_content
    .filter_list
    .action_calenda_filter_menu
    .filter_heading
    img {
    width: 28px;
  }
  .searchBox {
    width: 166px !important;
    height: 36px !important;
    margin-left: 113px;
  }
  .searchBox.mobileAutionSearchBtn {
    width: 40px !important;
    height: 40px !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-top: -3px;
  }
  .iconBox.searchIconBoxMobile {
    width: 22px;
    height: 17px !important;
    margin: auto;
  }
  .searchIconPopUp {
    margin-left: -35px;
    z-index: 2;
    padding-top: 5px;
  }
  .modalStyleSearchPopup.modal.show .modal-dialog {
    height: inherit;
  }
  .modalStyleSearchPopup .modal-content {
    height: inherit;
  }
  .modalStyleSearchPopup .modal-header {
    border-bottom: none !important;
  }
  .modalCloseIconSearchPopUp {
    width: 29px;
    height: 29px;
    margin-top: 6px;
    margin-left: auto;
  }
  .auctionNameValues {
    width: 100%;
    margin-top: 20px;
  }

  .filter_list_sort_section_sory_by {
    display: none;
  }
  .auctionNameValues > p > span {
    display: block;
    margin: 0;
  }
  .auctionNameValues p > span.auctionDetailsLeft {
    /* font-weight: bold; */
    /* color: #000; */
  }
  .auctionNameValues .adDate {
    margin: 0;
  }
  .auctionNameValues p {
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
  }
}

.searchBox {
  width: 482px;
  height: 50px;
  background: #ffffff;
  /* box-shadow     : 1px 1px 10px rgba(90, 90, 90, 0.2); */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.iconBox {
  width: 60px;
  height: 50px;
  margin-left: -56px;
  /* background     : #010101; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collection-filter-sec {
  display: flex;
  align-items: center;
  gap: 16px;
}
.sort-bu-sec-content {
  display: flex;
  align-items: center !important;
  gap: 10px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.sort-bu-sec-content select,
.sort-bu-sec-content select {
  cursor: pointer;
}
.searchBoxTextField {
  width: 100%;
  /* border: none !important; */
  height: 46px !important;
}

.searchBox > .form-control:focus {
  /* border: none !important; */
  box-shadow: none !important;
}

.searchBoxTextField::-webkit-input-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #959595;
}

.searchBoxTextField:hover,
.searchBoxTextField:focus,
.searchBoxTextField:active {
  /* border    : none !important; */
  box-shadow: none !important;
}

.auctionDetailSpacing {
  flex-direction: column;
  padding-left: 44px;
}
.auctionNameValues {
  flex-direction: column;
  margin-bottom: 24px !important;
}

.auctionDetailsLeft {
  font-family: "BentonSansRegular";
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}
.how-bidding-works-sec {
  margin-top: 24px;
}
.how-bidding-works-sec button {
  width: 100%;
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 7px;
  border-radius: 4px;
  color: rgba(51, 51, 51, 0.7);
}
.adDate {
  font-family: "BentonSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  margin-left: 58px;
}

.adTime,
.adOnlineAuction {
  font-family: "BentonSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #333333;
}

.adAccordion {
  width: 80%;
  margin-left: 23px;
  margin-top: 64px;
}

.adCardBlock {
  margin-top: 35px !important;
  flex-direction: column;
  padding-left: 20px;
}

.furtherInfoText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: rgba(51, 51, 51, 0.7);
}

.forAssistText {
  font-family: "BentonSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #333333;
}

.forAssistContent {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}

.adCardHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.adCardContent {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: rgba(51, 51, 51, 0.7);
}

.lotsNumberSpacing {
  margin-left: 34px !important;
}
.adTitle {
  font-family: "BentonSansRegular";
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;

  color: #333333;
}
.classViewMore {
  background-color: #000 !important;
  padding: 8px 12px;
  border: none !important;
}
.classViewMore:focus {
  box-shadow: unset !important;
}
.spinner_Loading_icon {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.action_tab_content
  .filter_list_data_main
  .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
.sticky-collection-tabs {
  display: grid;
  grid-template-columns: 15% 85%;
  gap: 2%;
}
.sticky-collection-tabs .profile-tabs.sticky {
  height: auto;
}
.sticky-collection-tabs .profile-tabs {
  height: 100%;
  padding-top: 48px;
}
.sticky-collection-tabs .ant-tabs-content-holder {
  display: none;
}
.sticky-collection-tabs .ant-tabs-tab {
  border-left: 1px solid #d8d8d8;
  margin: 0px !important;
  font-family: "BentonSansRegular";
  font-size: 12px;
  font-weight: 400;
  line-height: 12.12px;
  color: #111111;
}
.sticky-collection-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #000 !important;
}
.sticky-collection-tabs .ant-tabs-ink-bar {
  background: #000;
}
.coverImage-collection-details-text h2 {
  font-family: "BentonSansMedium";
  font-size: 18px;
  font-weight: 400;
  line-height: 18.18px;
  margin-bottom: 27px;
}
.coverImage-collection-details-text .collection-title-sec-overview p {
  font-family: "BentonSansMedium";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.action_tab_content
  .filter_list_data_main
  .public-DraftEditor-content[contenteditable="true"] {
  font-family: "BentonSansRegular";
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}
.coverImage-collection-details-text {
  border-bottom: 1px solid #c4c4c4;
}
.filter_list_data_main .auction-details {
  padding: 32px 32px;
}
.filter_list_data_main .auction-details h2 {
  font-family: "BentonSansMedium";
  font-size: 18px;
  font-weight: 400;
  line-height: 18.18px;
}
.filter_list_data_main .auction-details .auctionDetailSpacing.row {
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
}
.condition-sale {
  padding: 32px;
  border-top: 1px solid #c4c4c4;
}

.condition-sale h2 {
  font-family: "BentonSansMedium";
  font-size: 18px;
  font-weight: 400;
  line-height: 18.18px;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .how-bidding-works-sec button {
    width: 90%;
  }
  .sticky-collection-tabs {
    display: flex;
  }
}
