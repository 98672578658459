.profile_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="profile"] {
  color: #000;
  font-weight: 600;
  background: #000;
  color: #fff;
  border-radius: 4px;
}

.profile_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="profile"]:hover {
  /* background: #fff; */
}
.user-rejected-sec-con {
}
.user-rejected-sec-con-sec {
  border-radius: 5px;
  background: #fae0e4;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  padding: 20px;
  margin-bottom: 30px;
}
.user-rejected-sec-con-sec p {
  color: #000;
  font-family: Noto Serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0px;
}
.user-rejected-sec-con-sec button {
  border-radius: 4px;
  border: 1px solid #111;
  background: #000;
  color: #fff;
  font-family: Noto Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 20px;
  height: 40px;
}
.account_all_right_content_heading .btn-outline-primary,
.profile_no_activity .form-group-wrap .btn-outline-primary.btn-md,
.profile_no_activity .btm-content .btn-primary {
  border-color: #000;
  color: #000;
  font-size: 14px;
  padding: 10px 12px 12px;
  border-radius: 4px;
  font-weight: 500;
  line-height: 19px;
  font-family: "BentonSansMedium";
  background: #fff;
}
.blockedRedcoclor {
  color: red;
}
.account_all_right_content_heading .btn-outline-primary:hover,
.profile_no_activity .form-group-wrap .btn-outline-primary.btn-md:hover,
.profile_no_activity .btm-content .btn-primary:hover,
.profile_no_activity .btm-content .btn-primary {
  background: #000;
  color: #fff;
  border-color: #000;
}

.profile_no_activity .btm-content p {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  font-family: "Noto Serif", serif;
}

.profile_no_activity .form-group-wrap {
  font-family: "Noto Serif", serif;
}

.profile_no_activity .form-group-wrap label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin: 0 0 8px;
  font-family: "benton-sans", sans-serif;
}

.profile_no_activity .form-group-wrap p {
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  font-family: "benton-sans", sans-serif;
}
.custom_modal_box .modal-content .modal-body .form-text {
  font-family: "benton-sans", sans-serif;
}
.select_address_content .form-check .form-check-label {
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  font-family: "benton-sans", sans-serif;
  position: relative;
}
.select_address_content {
  margin-top: 50px;
}

.select_address_content p {
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  font-family: "benton-sans", sans-serif;
  margin-bottom: 0;
}
.select_address_content .select_address_content-heading {
  font-size: 16px;
}

.profile_page_past_bid {
  min-height: auto !important;
}

.select_address_content .form-check .form-check-label::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 5px;
  width: 14px;
  height: 14px;
  border: 1px solid #111;
  border-radius: 50px;
}

.select_address_content .form-check .form-check-label::after {
  content: "";
  position: absolute;
  left: -17px;
  top: 8px;
  width: 8px;
  height: 8px;
  background: #111;
  border-radius: 50px;
  transform: scale(0);
}

.select_address_content
  .form-check
  .form-check-input:checked
  ~ .form-check-label::after {
  transform: scale(1);
}

.select_address_content .form-check .form-check-input {
  opacity: 0;
}

.profile_page_past_bid.active .manage_btn,
.profile_page_past_bid.active .add_btn,
.profile_page_past_bid .cancel_btn,
.profile_page_past_bid
  .select_address_content
  .form-check.disabled
  .form-check-input,
.profile_page_past_bid
  .select_address_content
  .form-check.disabled
  .form-check-label::before,
.profile_page_past_bid
  .select_address_content
  .form-check.disabled
  .form-check-label::after {
  display: none;
}

.profile_page_past_bid.active .cancel_btn {
  display: block;
}

.profile_page_past_bid.active
  .select_address_content
  .form-check.disabled
  .form-check-input,
.profile_page_past_bid.active
  .select_address_content
  .form-check.disabled
  .form-check-label::before,
.profile_page_past_bid.active
  .select_address_content
  .form-check.disabled
  .form-check-label::after {
  display: block;
}

.profile_page_past_bid
  .select_address_content
  .form-check.disabled
  .form-check-label {
  pointer-events: none;
  user-select: none;
}

.profile_page_past_bid.active
  .select_address_content
  .form-check.disabled
  .form-check-label {
  user-select: auto;
  pointer-events: auto;
}

.profile_page_past_bid .select_address_content:nth-child(2) .form-check,
.profile_page_past_bid .select_address_content:nth-child(3) .form-check {
  padding-left: 0;
}

.profile_page_past_bid.active .select_address_content:nth-child(2) .form-check,
.profile_page_past_bid.active .select_address_content:nth-child(3) .form-check {
  padding-left: 20px;
}

.profile_page_past_bid.active .disabled .form-check-label {
  color: #111111;
}
.complete-profile div {
  display: flex;
  gap: 12px;
  background: #fff4f4;
  padding: 16px;
  margin: 16px;
  align-items: center;
}
.complete-profile p {
  font-family: "BentonSansRegular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.complete-profile button {
  min-width: 173px;
  height: 38px;
  font-size: 12px;
  font-family: "BentonSansMedium";
  background: #000;
  color: #fff;
  border-color: #000;
}
.edit-address-modal .modalBodyText {
  padding: 24px;
  font-size: 16px;
  font-family: "BentonSansRegular";
}
.edit-address-modal .modalDeleteBtn {
  font-family: "BentonSansMedium";
}
@media (max-width: 767px) {
  .edit-address-modal .modalBodyText {
    padding: 16px;
  }
  .complete-profile div {
    flex-direction: column;
  }
  .user-rejected-sec-con-sec {
    display: block;
  }
  .user-rejected-sec-con-sec button {
    margin-top: 20px;
  }

  .account_all_right_content
    .account_all_right_section
    .account_all_right_content_heading
    h4 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 500;
    font-size: 24px !important;
    line-height: 24px !important;
    text-align: left;
    vertical-align: top;
    color: #000000 !important;
  }
  .account_all_right_content_heading .text-right {
    /* display: none !important; */
  }
  .account_all_right_content_heading .btn-outline-primary,
  .profile_no_activity .form-group-wrap .btn-outline-primary.btn-md,
  .profile_no_activity .btm-content .btn-primary {
    padding: 10px 9px 12px;
    min-width: 128px;
  }
  .profile_no_activity .form-group-wrap label {
    margin-bottom: 4px;
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    vertical-align: top;
    color: #000000;
  }
  .profile_no_activity .form-group-wrap .info {
    padding-top: 10px;
  }
  .profile_no_activity .form-group-wrap p {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    vertical-align: top;
    color: #000000;
  }
  .profile_no_activity .btm-content p {
    font-size: 14px;
  }
  .select_address_content {
    max-width: 47%;
    padding-bottom: 18px;
    margin-left: 10px;
  }
  .select_address_content .form-check .form-check-label,
  .select_address_content p {
    font-size: 14px;
    line-height: 19px;
  }
}

.editDeleteUser {
  margin-top: 20px;
}
.editDeleteUser span {
  cursor: pointer;
  text-decoration: underline;
}
.editDeleteUser span:nth-child(2) {
  margin-left: 10px;
}
.passwordErrorError {
  margin-left: 20px;
  color: red;
}

.profileBtns {
  background-color: #ffffff !important;
  color: #000000 !important;
}
.profileBtns:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
.profileBtns:hover,
.profileBtns:active {
  background-color: #000000 !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.btn-md:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
