.more_collection_colum .more_from {
  padding: 50px 3% 100px;
}

.more_collection_colum .more_from .more_from_title_sec {
  font-family: "Noto Serif", serif;
  margin-bottom: 25px;
}

.more_collection_colum .more_from .more_from_title_sec h6 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
}
.more_collection_colum_lot_details {
  margin-top: 70px;
}
.more_collection_colum .more_from .more_from_title_sec .title_1 {
  font-size: 30px;
  color: #333333;
  line-height: 40px;
  font-weight: 600;
  width: 90%;
  font-family: Mercury Display A, Mercury Display B, MercuryDisplay, serif;
}
.more-collect-view-all-button {
  font-family: "BentonSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  color: #000000;
  width: 140px;
  min-width: 140px;
  border: 1px solid #000000;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-transform: uppercase;
  text-decoration: none;
}
.more-collect-view-all-button:hover {
  background-color: #000000;
  color: #fff;
  text-decoration: none;
}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont
  .lot_number {
  font-weight: 500;
  font-size: 14px;
  padding: 6px 14px;
  color: #333333;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-bottom: 8px;
}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_img
  img {
  border-radius: 5px;
  cursor: pointer;
}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  h4 {
  min-height: 34px;
  line-height: 26px;
  font-weight: 300;
  font-size: 20px;
  margin: 10px 0;
  font-family: "Mercury Display A, Mercury Display B, MercuryDisplay-wb, Mercury Display",
    serif;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 54px;
  text-transform: uppercase;
}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  .lots_cost
  span {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "BentonSansLight" !important;
  text-transform: uppercase;
}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  .lots_cost
  span.strong {
  color: #333;
  font-weight: 400;
  font-family: "BentonSansRegular" !important;
  font-size: 12px;

}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  .btn_lots
  .lots_btn_same {
  font-size: 14px;
  margin: 0 2px;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: 500;
  color: #333333;
  border: 1px solid #333333;
  vertical-align: top;
  height: 37px;
  line-height: 37px;
  background-color: #fff;
}
.collection-title-devision{
  width:100%;
}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  .btn_lots
  .lots_btn_same:hover {
  background-color: #000;
  color: #fff;
}

.more_collection_colum
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  .btn_lots
  .lots_btn_same
  .bid_icon {
  width: 15px;
}

.view_all_from {
  font-weight: 500;
  font-size: 20px;
  width: 140px;
  height: 46px;
  line-height: 46px;
  border: 1px solid #111111;
  border-radius: 4px;
  color: #333333;
  font-family: "Poppins", sans-serif;
}

.view_all_from:hover {
  color: #333333;
}

@media (max-width: 991px) {
  .more_collection_colum .more_from {
    padding: 40px 15px 80px;
  }
  .more_collection_colum .more_from .more_from_title_sec h6 {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .more_collection_colum .more_from .more_from_title_sec .title_1 {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .view_all_from {
    border: unset !important;
  }
}
