.browse_listing_inner_content .filter_list_data_column .data_column_box {
  margin-bottom: 50px;
}
.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lot_number {
  font-weight: 500;
  font-size: 14px;
  padding: 6px 14px;
  color: #333333;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-bottom: 8px;
  font-family: "BentonSansMedium", sans-serif;
}
.lotSoldPriceList span {
  font-weight: bold;
}
.lots_cost_lat_coll_details {
  margin-bottom: 10px;
}
.lot-grid-view-sec-estimate-follow .btn_lots_font_issue {
  display: flex;
  justify-content: space-between;
}
.grid-view-com {
  position: relative !important;
}
.grid-view-com .btn_lots_font_issue .lots_btn_same {
  width: 47%;
  justify-content: center;
}
.grid-view-com .btn_lots_font_issue {
  position: absolute;
  bottom: -32px;
  width: 90%;
  display: flex;
}
.lotunSoldPriceList {
  color: rgba(0, 0, 0, 0.5);
}
.lineClampLot {
  min-height: 40px !important;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.makers-name {
  font-size: 26px;
  font-weight: 400;
  margin-top: 12px;
  font-family: "Mercury Display A", "Mercury Display B", "MercuryDisplay-wb",
    "Mercury Display", serif;
  text-transform: uppercase;
  color: #000;
}
.makers-name.lot-page {
  font-size: 26px;
}
.place-bid-page.makers-name {
  font-size: 26px;
}

.price-block {
  max-width: 140px !important;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  min-height: 52px;
  margin: 10px 0;
  font-family: "Mercury Display A", "Mercury Display B", "MercuryDisplay-wb",
    "Mercury Display", serif;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .lots_cost
  span {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "BentonSansLight";
  text-transform: uppercase;
}
.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .lots_cost
  .currency-info {
  font-family: "BentonSansRegular";
  font-size: 12px;
  color: #333;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .lots_cost
  span.strong {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-family: "BentonSansMedium", sans-serif;
}
.browse_listing_inner_content .registered-button-text {
  background: #333 !important;
  color: #ffffff !important;
  cursor: auto !important;
}
.browse_listing_inner_content .registered-button-text.bid-button-collec {
  cursor: pointer !important;
}
.lots_slider_cont_details a,
.lots_slider_cont_details a:hover {
  color: #212529;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
  font-family: "Mercury Display A", "Mercury Display B", "MercuryDisplay-wb",
    "Mercury Display", serif;
  text-transform: uppercase;
}
.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .btn_lots
  .lots_btn_same {
  font-family: "BentonSansMedium", sans-serif;
  font-size: 14px;
  margin: 2px;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: 500;
  color: #333333;
  border: 1px solid #333333;
  height: 37px;
  vertical-align: top;
  line-height: 37px;
  background: #fff;
}
.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .btn_lots
  .lots_btn_same:hover {
  background: #333 !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 8%) inset !important;
  cursor: pointer;
}
.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .btn_lots
  .lots_btn_same
  img {
  width: 15px;
}

.load_more_list_btn a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: #333333;
  border-radius: 5px;
  padding: 10px 20px;
}
.no-results-sec-coll-details {
  color: #111;
  text-align: center;
  font-family: Noto Serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  opacity: 0.4000000059604645;

  width: 50%;
  margin: auto;
  margin-top: 100px;
}

.card-box-top-right-new-cd {
  font-size: 12px !important;
  margin-bottom: 10px !important;
}
@media (max-width: 991px) {
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .btn_lots {
    display: flex;
  }
  .lot-grid-view-sec-estimate-follow .btn_lots_font_issue {
    gap: 20px;
    justify-content: flex-start;
  }

  .no-results-sec-coll-details {
    width: 80%;
  }
}

@media (min-width: 990px) and (max-width: 1370px) {
  .btn_lots_font_issue {
    display: flex;
  }
  .btn_lots_font_issue button {
    font-size: 13px;
    padding: 0px 4px;
  }
  .btn_lots_font_issue a {
    padding: 0px 5px;
    font-size: 12px;
  }
}
.followingThisBid {
  background-color: #333 !important;
  color: #fff !important;
}
.bidding-start-text {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  span {
    font-family: "BentonSansLight";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
  .textSize {
    font-size: 12px !important;
    font-weight: 400;
    font-family: "BentonSansRegular";
    color: #333;
  }
}
.bidding-not-started {
  font-family: "BentonSansMedium";
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.bidding-open-text {
  font-family: "BentonSansMedium";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.grid-price-block {
  color: #333 !important;
  font-family: ui-rounded;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.375rem;
  font-family: "BentonSansLight", sans-serif;
}
.grid-price-block p {
  color: #333;
  font-family: "BentonSansRegular";
  font-size: 12px;
}
.grid-price-block1 {
  font-size: 12px !important;
  font-family: BentonSansLight;
  font-weight: 400 !important;
  color: #333 !important;
}
.lot-currentbid-text {
  font-size: 14px !important;
  font-family: BentonSansLight !important;
  font-weight: 600 !important;
}
.bids-count-no {
  font-size: 12px !important;
  font-family: BentonSansLight !important;
  font-weight: 400 !important;
}
.place-bid.bids-count-no {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 34px !important;
}
@media (max-width: 600px) {
  .bidding-start-text {
    span {
      font-size: 13px;
    }
    .textSize {
      font-size: 13px !important;
    }
  }
}
@supports (-webkit-appearance: none) and (stroke-color: transparent) {
  .bids-count-no {
    font-weight: 400 !important;
  }
  .grid-price-block1 {
    font-weight: 400 !important;
  }
}
@media (max-width: 767px) {
  .makers-name {
    font-size: 22px;
  }
  .list-view.makers-name {
    font-size: 22px;
  }
  .makers-name.place-bid-mobile {
    font-size: 22px;
    color: #fff;
  }
}
