/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

.inner_banner_slider .banner_left {
  background: #f5f5f5;
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-bottom: 0;
}

.banner_left .banner_breadcrumb {
  padding: 20px;
  padding-bottom: 30px;
}
.disable-lot-regis-sec {
  opacity: 0.6;
}
.lot-details-timer-sec {
  margin-bottom: 20px;
}
.lot-details-timer-sec .lot-begin-text {
  color: rgba(0, 0, 0, 0.5);
  font-family: "BentonSansLight";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}
.lot-details-timer-sec .lot-begin-date {
  color: #000;
  font-family: "BentonSansRegular";
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.lot-details-timer-sec p {
  margin-bottom: 2px;
}
.lot-no-sec-top-details-page {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 6px 14px;
  margin-bottom: 24px;
  color: #111;
  font-family: benton-sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.56px;
}
.banner_left .banner_breadcrumb .breadcrumb_left .breadcumb_btn {
  font-weight: 500;
  font-size: 14px;
  font-family: "benton-sans", sans-serif;
  color: #333333;
}
.lots_btn_same_placeBid {
  position: relative;
}
.loaderClassforLotDetails {
  height: 500px;
}
.breadcumb_btn_lot_d {
  font-weight: 400;
  color: #212529 !important;
  background: #fff !important;
  border: none !important;
  font-family: "benton-sans", sans-serif;
}
.your-max-bid-amount-text-lot-details-page {
  margin-bottom: 10px;
  color: #50ae00 !important;
}
.is-page-loading-bid-history {
  text-align: center;
  font-size: 20px;
}
.loaderClassforLotDetails svg {
  width: 100%;
  height: 500px;
}
.banner_left .banner_breadcrumb .breadcrumb_right .breadcumb_btn {
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #333333;
}
.bid-count-cursor {
  cursor: pointer;
}
.lot-follow-btn-back.lots_btn_same {
  background: #fff !important;
  color: #333 !important;
}
.lot-follow-btn-back.lots_btn_same:hover {
  background: #333 !important;
  color: #fff !important;
}
.bids-count-number0 {
  cursor: auto !important;
  text-decoration: none !important;
}
.inner_banner_slider .banner_left .lot_no {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 14px;
  color: #333333;
  margin-left: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-left: 50px;
}
.bgCOlorforBtn {
  background-color: #f5f5f5 !important;
  border: none !important;
}
.inner_banner_slider .banner_right_cont {
  width: 100%;
  right: 50%;
  top: 24%;
  margin-right: -48%;
  z-index: 99;
}

.inner_banner_slider .banner_right_cont .right_cont_details_box {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 30px;
}

.inner_banner_slider .banner_right_cont .right_cont_details_box h4 {
  font-family: "Mercury Display A, Mercury Display B, MercuryDisplay-wb, Mercury Display",
    serif;
  font-weight: 325;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 40px;
  margin-bottom: 20px;
}

.inner_banner_slider .banner_right_cont .right_cont_details_box .lots_cost {
  margin-bottom: 20px;
}

.inner_banner_slider
  .banner_right_cont
  .right_cont_details_box
  .lots_cost
  span {
  font-size: 12px;
  font-weight: 400;
  font-family: "BentonSansLight";
  color: #00000080;
  text-transform: uppercase;
  line-height: normal;
  margin-bottom: 4px;
}
.inner_banner_slider
  .banner_right_cont
  .right_cont_details_box
  .lots_cost
  span.strong {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  font-family: "BentonSansRegular";
}
.lots_cost .lotDetailsTextRightSide {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 24px;
  color: #000;
  font-family: "BentonSansLight" !important;
}
.lot-currentbid-text .grid-price-block1 {
  font-family: "BentonSansRegular" !important;
}

.lotDetailsTextRightSide {
  font-family: "benton-sans", sans-serif;
}

.right_cont_details_box .btn_lots .lots_btn_same:first-child {
  margin-left: 0;
}

.right_cont_details_box .btn_lots .lots_btn_same {
  font-weight: 500;
  font-size: 14px;
  height: 48px;
  padding: 0px 5px;
  color: #333333;
  border: 1px solid #333333;
  border-radius: 4px;
  font-family: benton-sans, sans-serif;
}

.right_cont_details_box .btn_lots .lots_btn_same.bid-white {
  background: #333333;
}

.banner_right_cont .cur_convt {
  margin-top: 12px;
}

.banner_right_cont .cur_convt a {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #333333;
}

.custom_breadcrumb .breadcrumb {
  background: transparent;
  border: 0;
  padding: 0;
}

.custom_breadcrumb .breadcrumb .breadcrumb-item a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 137px;
  display: block;
  color: #000000;
}

.custom_breadcrumb .breadcrumb .breadcrumb-item {
  font-weight: 300;
  font-size: 12px;
  color: #000000;
}

.read_more_discription .collapse.show ~ .card-header button .read_less {
  display: block !important;
}

.read_more_discription .collapse.show ~ .card-header button .read_more {
  display: none;
}

.read_more_discription .card-header button {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-decoration-line: underline;
  box-shadow: none;
}

.image-gallery-swipe {
  display: flex;
  justify-content: center;
  width: 62%;
  margin: auto;
}

.modalCloseIcon {
  width: 29px;
  height: 29px;
  margin-top: 6px;
}
.main-content-lot-details {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.lot-bidding-works-items-sec {
  display: flex;
  justify-content: space-between;
}
.lot-bidding-works-items-sec-how-b {
  /* border-right: 1px solid rgba(0, 0, 0, 0.4); */
  /* padding-right: 9%; */
}
.dash-line-bidding-sec {
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  margin-top: 12px;
}
.btn_lots {
  gap: 12px;
}
.lot-details-timer-sec {
  font-family: "BentonSansMedium";
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    font-family: "BentonSansLight";
    text-transform: uppercase;
  }
}
.lot-details-timer-sec .starts-date {
  font-family: "BentonSansRegular";
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
}
.lot-details-timer-sec .info-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  font-family: BentonSansRegular, sans-serif;
  text-transform: uppercase;
}
.lot-details-timer-sec .danger-text-info {
  color: red;
  text-transform: uppercase;
}
.lot-bidding-open-text,
.lot-bidding-not-started {
  font-family: "BentonSansMedium", sans-serif !important;
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  line-height: 26px;
  text-align: left;
  margin-bottom: 16px;
  color: #000 !important;
}
.lot-bidding-not-started.active-bids-closed {
  margin-bottom: 0px;
}
.bidding-date-closed-text {
  font-size: 12px;
  font-family: "BentonSansRegular";
  font-weight: 400;
  margin-bottom: 16px;
  text-transform: uppercase;
}
/* media query css start */

@media (max-width: 1200px) {
  .right_cont_details_box .btn_lots {
    flex-direction: column;
  }
  .right_cont_details_box .btn_lots .w-50 {
    width: 100% !important;
    padding: 8px 0 !important;
  }
  .inner_banner_slider .banner_right_cont {
    top: 17%;
  }
  .inner_banner_slider .banner_right_cont .right_cont_details_box h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 991px) {
  .inner_banner_slider .banner_right_cont {
    position: relative !important;
    top: 0;
    width: 100%;
    right: 0;
  }
  .inner_banner_slider .banner_left {
    width: 100%;
  }
  .image_gallery_slider .image-gallery-slide.center {
    padding-right: 0;
  }
  .inner_banner_slider .banner_left,
  .inner_banner_slider .banner_right_cont .right_cont_details_box {
    background: transparent;
    border: 0;
    padding: 0px 15px;
  }
  .inner_banner_slider .banner_right_cont .right_cont_details_box {
    padding: 30px 15px 20px;
  }
  .inner_banner_slider .banner_left .lot_no {
    margin-left: 0;
  }
  .image_gallery_slider .image-gallery-slide.center {
    max-height: 450px;
  }
  .banner_right_cont .cur_convt {
    margin-top: 0;
    padding: 0 15px;
  }
  .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box
    .lots_cost.lots_cost_lat {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 18px;
  }
  .right_cont_details_box .btn_lots .lots_btn_same {
    height: 41px;
    line-height: 41px;
    font-size: 14px;
  }
  .right_cont_details_box .btn_lots .lots_btn_same img {
    width: 18px;
  }
  .banner_right_cont .cur_convt a {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .inner_banner_slider .banner_right_cont .right_cont_details_box h4 {
    font-size: 24px;
    line-height: 22px;
  }
  .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box
    .lots_cost
    span,
  .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box
    .lots_cost
    span.strong {
    font-size: 12px;
  }
  .image_gallery_slider .image-gallery-slide.center {
    max-height: 200px;
  }
  .image_gallery_slider .image-gallery-slide.center .image-gallery-image {
    max-height: 200px;
    object-fit: contain;
  }
}

@media (max-width: 600px) {
  .inner_banner_slider .banner_right_cont .right_cont_details_box .lots_cost {
    margin-bottom: 20px;
  }
  .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box
    .lots_cost
    span.d-block.lotStatusText {
    font-family: "poppins";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    vertical-align: top;
  }
  .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box
    .lots_cost.lots_cost_lat
    span.strong.d-block.lotStatusText.rupees {
    font-family: "poppins";
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    vertical-align: top;
    color: #333333;
  }
  .d-block.lotStatusText {
    display: unset !important;
  }
  .strong.d-block.lotStatusText {
    font-family: "poppins";
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    vertical-align: top;
    float: right;
  }
  .lot-bidding-not-started.active-bids-closed {
    position: absolute;
    bottom: 0px;
    left: 22px;
    margin-bottom: 12px;
  }
  .lots_cost_lat_coll_details {
    margin-bottom: 16px;
  }
  .card-box-con-estimate {
    margin-top: 12px !important;
  }
  .card-box-con-middle-row {
    margin-left: 0px;
  }
}

/* media query css end */

.CurrencyConverterBtn {
  background-color: #ffffff !important;
  border: none !important;
  color: #000000 !important;
  padding: 0px !important;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  text-align: left;
  vertical-align: top;
  float: right;
}

.CurrencyConverterBtn:hover,
.CurrencyConverterBtn:active,
.CurrencyConverterBtn:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.CurrencyModalHeader {
  border-bottom: none !important;
  padding: 44px 40px 32px !important;
}

.custom-modal-style {
  width: 83% !important;
}

.CurrencyShowDiv {
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
  padding: 0px 40px !important;
}
.CurrencyModalHeader svg {
  cursor: pointer;
}
.currencydiv {
  width: 100%;
  padding-top: 40px;
  height: 278px;
}
.currencydiv1 {
  width: 100%;
  padding-top: 0px;
  height: 278px;
}

.table-striped tbody .CountryTr:nth-of-type(odd) {
  background-color: rgb(249, 249, 249);
}

.CountryTd {
  padding: 0px !important;
  width: 100%;
}
.noResults {
  text-align: center;
  margin-top: 50px;
}
.CountryButton {
  color: #000000 !important;
  background-color: transparent !important;
  border: none !important;
  align-items: center;
  cursor: pointer;
  display: flex !important;
  font-family: benton-sans, sans-serif;
  font-size: 14px;
  justify-content: center !important;
  letter-spacing: 0.25px;
  overflow: hidden;
  padding: 8px !important;
  width: 100%;
  gap: 20px;
}

.CountryButton:hover,
.CountryButton:active,
.CountryButton:focus {
  border: none !important;
  outline: 0 !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  box-shadow: none !important;
  display: flex;
}

.CurrrencyImageSpan {
  margin-left: 8px;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .CurrrencyImageSpan {
    /* margin-left: 8px;
    margin-right: 8px; */
  }
}

.CountryImage {
  width: 32px;
  height: 32px;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 20px;
  /* float: left; */
}

.CountryCode {
  float: left;
  padding-left: 10px;
  font-family: benton-sans, sans-serif;
  font-size: 1rem;
  font-weight: 545;
  letter-spacing: 0.015625rem;
  line-height: 1.5rem;
  padding-top: 5px;
}

.CountryCodeSpan {
  padding-top: inherit;
}

.CountryCodeText {
  font-family: benton-sans, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.015625rem;
  line-height: 1.5rem !important;
  padding-left: 10px;
  margin: 0px !important;
}

.CountryNameSpan {
  width: 100%;
  text-align: right;
  margin-right: 16px;
}

.CountryName {
  font-family: benton-sans, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  margin: 0px !important;
}

.CustomFooter {
  border-bottom: none !important;
  border-top: 0px !important;
  padding: 24px 40px 40px 24px !important;
}

/* .FooterCloseBtn {
  color: #ffffff !important;
  width: 148px;
  height: 50px;
  background: #000000;
  border: none !important;
  background-color: #000000 !important;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
} */

.currency-title-main-sec {
  color: #111;
  font-family: Noto Serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
.lot-details-place-bid-button {
  background-color: #fff;
}
.lot-details-place-bid-button:hover {
  background: #000;
  color: #ffff !important;
}
.CurrencyShowDiv-sub-title {
  color: #000;
  font-family: Noto Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
}
.selected-currency-section {
  border-bottom: 1px solid #dddcdc;
  padding-bottom: 20px;
}
.selected-currency-section > span {
  color: #000;
  font-family: Noto Serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 186.667% */
  margin-top: 16px;
  display: block;
}
.selected-currency-section-bottom {
  background: #f9f9f9;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  padding: 17px 30px;
}
.selected-currency-section-bottom-key {
  color: #555;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.14px;
}
.selected-currency-section-bottom-value {
  color: #555;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.14px;
}
.FooterCloseBtn-currency,
.FooterCloseBtn-currency:hover {
  border-radius: 4px;
  border: 1px solid #000;
  color: #111;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  background-color: #fff;
}
.bids-count-no {
  text-decoration: underline;
}
.place-bid.bids-count-no {
  font-size: 16px;
  text-transform: uppercase;
}
.resevere-met-sec {
  color: rgba(255, 255, 255, 0.74);
  font-family: "BentonSansLight";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  text-transform: uppercase;
}
.bids-count {
  cursor: pointer;
}
.selected-currency-sec-name {
  color: #2ca900;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
  margin-top: -15px;
}
.prev-bid-details-sec {
  padding: 17px 40px;
  display: flex;
  justify-content: space-between;
}
.selected-currency-sec-name1 {
  color: #2ca900;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
  margin-top: -0px;
}
.StylingText {
  text-align-last: end;
}
@media only screen and (min-width: 600px) {
  .lot-details-1 .lot-detils-sec-top button {
    padding-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .currencydiv {
    width: 100% !important;
  }
  .currencyTable {
    width: 100% !important;
  }
  .CurrencyTableBody {
    width: 100% !important;
  }
  .custom-modal-style,
  .CustomModal {
    width: 100% !important;
  }
  .CurrencyShowDiv {
    width: 100% !important;
  }
  .CountryTr {
    width: 100% !important;
  }
  .IncrementTableDiv {
    max-height: 263px;
    width: 100%;
    overflow: auto;
    overflow-x: scroll;
    padding: 0px 0px !important;
    display: block;
  }
  .bidIncrementModal {
    width: 100% !important;
  }
  .resevere-met-sec {
    font-size: 10px;
    line-height: 24px;
  }
}
.bidIncrementModal .modalCloseIcon {
  cursor: pointer;
}

@media (min-width: 900px) {
  .inner_banner_slider .banner_left {
    float: left;
  }
  .inner_banner_slider .banner_right_cont {
    position: initial;
    display: flow-root;
    float: left;
    /* margin-left: -12%; */
    z-index: 19999999;
    /* margin-top: 200px; */
    margin-right: unset !important;
  }
  .lot-detils-sec-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .lot-detils-sec-top .breadcumb_btn {
    color: #000;
  }
  .lot-detils-sec-top button,
  .lot-detils-sec-top button:hover {
    background: none;
    border: none;
    color: #000;
  }
  .lot-detils-sec-main-content {
    display: flex;
    gap: 50px;
  }
  .product_description_mobile {
    display: none;
  }
  .lot-detils-main-content-left {
    width: calc(100% - 530px);
  }
  .lot-detils-main-content-right {
    width: 480px;
    position: sticky;
    top: 10px;
    /* width: 140px; */
    height: 450px;
  }
  .lot-details-1 .lot-detils-main-content-right .banner_right_cont {
    margin: 0;
  }
  .lot-details-1
    .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box {
    padding: 24px;
  }
}

@media (max-width: 600px) {
  .custom-modal-style {
    width: 100% !important;
  }
  .BidIncrementTableText {
    font-size: 10px !important;
  }
  .place-bid.bids-count-no {
    font-size: 10px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 667px) {
  .custom-modal-style {
    width: 100% !important;
  }
}

@media (max-width: 769px) {
  .custom-modal-style {
    max-width: 522px !important;
  }
}

@media (min-width: 576px) {
  .custom-modal-style {
    max-width: 552px !important;
    margin: 1.75rem auto;
    max-height: 90%;
  }
}

@media (min-width: 667px) {
  .custom-modal-style {
    max-width: 100% !important;
    margin: 1.75rem auto;
    max-height: 90%;
  }
  .table td:not(:first-child) {
    padding-left: 22px;
  }
}

@media (min-width: 985px) {
  .custom-modal-style {
    max-width: 100% !important;
    margin: 1.75rem auto;
    max-height: 90%;
  }
}

.IncrementTableDiv {
  max-height: 263px;
  overflow: auto;
  padding: 0px 0px !important;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}

table thead,
table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  text-align: justify;
  font-family: benton-sans, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  box-sizing: inherit;
  border-top: 0px !important;
}

td {
  text-align: inherit;
  padding-left: 0px;
}
.table td,
.table th {
  border: 0px !important;
}

.BidIncrementTableText {
  display: block;
  font-family: benton-sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  cursor: pointer;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.InfoIconSpan {
  margin-right: 10px;
  width: 20px;
}

.infinitySymbolStyles {
  width: 60px;
  height: 30px;
}

.BidIncrementHeader {
  border-bottom: none !important;
  padding: 16px 16px 10px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.BidIncrementTitle h1 {
  font-size: 35px;
}
.BidIncrementdiv {
  width: 100%;
  border: 1px solid #e2e2e2;
  margin-bottom: 20px;
  border-radius: 4px;
}
.BidIncrementBody {
  padding: 0px 16px !important;
}
.BidIncrementTitle {
  font-family: Mercury Display A, Mercury Display B, MercuryDisplay-wb,
    Mercury Display, serif;
  font-size: 1.7rem;
  font-weight: 325;
  letter-spacing: 0;
  line-height: 2rem;
}

.times {
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  font-family: benton-sans, sans-serif;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0.25px;
  overflow: hidden;
  margin-right: 10px;
  background-color: #ffffff !important;
  border: none !important;
  color: #000000 !important;
  padding: 8px;
  display: grid !important;
}

.times:hover,
.times:active,
.times:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

@media (max-width: 900px) {
  .product_description_desktop {
    display: none;
  }
}
