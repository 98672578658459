.bid-pop-body-sec {
  display: flex;
}
.bid-close-icon-button {
  width: 46px;
  height: 18px;
  position: absolute;
  top: 8px;
  right: -5px;
  cursor: pointer;
}
.bid-pop-body-sec {
  padding: 12px 12px 12px 0px !important;
}
.bid-popover-text {
  color: #000 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important; /* 164.286% */
  letter-spacing: 0.25px !important;
  margin-bottom: 0px;
  margin-right: 30px;
  margin-left: 10px;
}
.bid-popover .bs-popover-auto .popover-arrow {
  right: -14px !important;
  left: auto !important;
  rotate: 180deg;
  top: 76px !important;
}
.place-bid-popover .popover {
  z-index: 9999 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 0px 6px -2px;
}
.bid-popover .popover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 0px 6px -2px;
}

.place-bid-popover .bid-pop-body-sec .bid-popover-text {
  margin-left: 12px;
  margin-right: 34px;
}
.place-bid-popover .bid-pop-body-sec .bid-close-icon-button {
  top: 17px;
  right: 5px;
  left: auto;
}
.place-bid-popover .popover .popover-arrow {
  right: -14px !important;
  left: auto !important;
  rotate: 180deg;
  top: 83px !important;
}
.popover .popover-arrow {
  display: block !important;
}
.popover .arrow::after,
.popover .arrow::before {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff !important;
}
.button-popover {
  border: none;
  background: none;
}
.place-bid-popover .popover {
  border: none;
}
.button-popover img {
  width: 17px;
  height: 17px;
}
