.actionDropdownToggle::after {
  display: none;
  background-color: #fff8ef !important ;
}
body select {
  -webkit-appearance: none;
}
.time-red-color,
.timer-low-sec-count0 {
  color: red;
}
.actionsDropdown > a {
  font-family: BentonSans, sans-serif;
}
.actionsDropdown > button {
  font-family: BentonSans, sans-serif;
  font-weight: bold;
  padding: 4px;
  font-weight: 500;
  line-height: 21px;
  font-size: 14px !important;
  text-align: left;
  color: #111111;
  color: #555555;
  background: #ffffff;
  border-radius: 4px #fff8ef !important;
  border-color: #ffffff !important ;
  box-shadow: unset !important;
}
.logo_main_new {
  max-height: 60px;
}
.alignCenter {
  align-items: center;
}
.navBocHeaderText p {
  font-size: 18px;
  margin-bottom: 0px;
  color: red;
}
.actionsDropdown > button:hover,
.actionsDropdown > button:active,
.actionsDropdown > button:focus {
  background: #fff8ef !important;
  color: #000000 !important;
  border-radius: 4px #fff8ef !important;
  border-color: #ffffff !important ;
}

.actionsDropdown.show > .btn-primary.dropdown-toggle {
  color: #111111 !important;
  background-color: #fff8ef !important;
}

.actionsDropdown.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset !important;
  background: #fff8ef !important;
}

.dropdownText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  padding-left: 5px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #000000;
}
.dropdown-item > .dropdownText:active .dropdown-item > .dropdownText:focus {
  background-color: #ffffff !important;
}

.dropdown-item.dropdownText:hover {
  background: #ffffff !important;
  color: #111111;
}

.noBorder {
  border: none !important;
  border-radius: 0px;
}
.linksSell {
  margin-left: 27px !important;
}
.header-top-box {
  height: 30px;
  background: #000;
}
.header-top-box-content {
  width: 85%;
  margin: auto;
}
.header-top-box-content ul {
  display: flex;
  justify-content: right;
  list-style-type: none;
}
.header-top-box-content a {
  color: #fff;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 35px;
  cursor: pointer;
}
.header-top-box-content a:hover {
  text-decoration: none;
  color: #fff;
}

.input-group-btn {
  float: right;
  margin-top: -40px;
}
.input-group-btn span {
  cursor: pointer !important;
  position: relative;
}
.input-group-btn img,
.input-group-btn img:hover {
  cursor: pointer !important;
}
.create-password-sec-error .input-group-btn {
  margin-right: 20px;
}
.password-hide-img {
  height: 20px;
}
.row-item-sec-pay {
  /* margin-right: 100px; */
  text-decoration: underline;
}
.row-item-sec-pay a {
  color: #fff;
}
.banner-notification-sec {
  text-align: center;
  display: block;
}
.close-notification-icon {
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .actionsDropdown > button,
  .linksSell,
  .linksAbout {
    font-family: "Poppins" !important;
    padding: unset !important;
    font-weight: 500 !important;
    font-style: normal;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #111111 !important;
    padding-bottom: 16px !important;
    text-transform: unset !important;
    margin-left: 10px !important;
  }
  .actionsDropdown > button {
    padding: 10px !important;
  }
  .actionsDropdown,
  .actionsDropdown a {
    text-align: center;
  }
  .page_header .actionsDropdown .dropdown-menu {
    margin-left: 30px !important;
  }
  .login_keepmelogin_btn .keep-margin-login-check {
    margin-bottom: 20px !important;
    display: block;
  }
}
@media (max-width: 989px) {
  .actionsDropdown,
  .actionsDropdown a {
    text-align: center;
  }
}

.slide-image {
  position: relative;
}
.slide-image h1 {
  display: none;
}
.slide-image .slide-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #000;
}
.slide-image .slide-image img {
  width: 100%;
  z-index: 1;
}

.slideshow-slider-dots {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  left: 0;
  bottom: 25px;
  z-index: 20;
  width: 100%;
  height: auto;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
}
.slideshow-slider-dots li {
  position: relative;
  display: inline-block;
  margin: 0 0.5rem;
  padding: 0;
  cursor: pointer;
  color: #a6a6a6;
  background: #c4c4c4;
  width: 144px;
  text-align: left;
  height: 4px;
  border-radius: 28px;
}
.slideshow-slider-dots li a {
  font-family: "DIN Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 111.9%;
  letter-spacing: 0.075em;
  color: #ffffff;
}

.slideshow-slider-dots li.slick-active::before {
  height: 100%;
  content: "";
  width: 100%; /* Full width of the progress line */
  position: absolute;
  background: #ccc; /* Base line color */
  transform-origin: left;
  overflow: hidden; /* Ensure the progress bar stays within bounds */
  border-radius: 28px;
}

.slideshow-slider-dots li.slick-active::after {
  height: 100%;
  content: "";
  width: 100%; /* Full width of the progress bar */
  position: absolute;
  background: #FFA600; /* Progress bar color */
  transform-origin: left;
  animation: animate 3s linear infinite; /* Progress animation */
  top: 0px;
  border-radius: 28px;
}

@keyframes animate {
  0% {
    transform: scaleX(0); /* Start from 0% width */
    border-radius: 28px;
  }
  100% {
    transform: scaleX(1); /* Grow to 100% width */
    border-radius: 28px;
  }
}
