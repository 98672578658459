.brows_list_content_page .container {
  width: 1370px;
}

.brows_list_content_page {
  padding-bottom: 40px;
}
.lots_slider_img,
.list_view_img,
.lots_slider_img a {
  width: 100%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
}
.login-view-results-top {
  margin-top: 24px;
}
.login-view-results-top button {
  color: #000;
  font-family: "BentonSansRegular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  padding: 14px 32px;
  text-transform: uppercase;
}
.login-view-results-top button:hover {
  background: #333 !important;
  color: #ffffff !important;
}
.active-bids-green-colr.green-color-amount-text {
  font-size: 16px;
}
/* .lot-page-border a,
.more-collection-border {
  border: 1px solid #dbdbdb;
} */
.filter_list_sort_section .custom_select_dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../../assets/arrow-down-s-line.svg) no-repeat right center;
  background-size: 16px;

  cursor: pointer;
}

.show-timer-sec-sec-cont .place-bid-time-sec-count-time {
  display: flex !important;
  justify-content: end;
  gap: 5px;
  width: 100%;
  display: block;
}
.show-timer-sec-sec-cont p {
  margin-bottom: 0px;
}
.lots_slider_img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modalDeleteBtn {
  border: none !important;
}

@media (max-width: 1366px) {
  .brows_list_content_page .container {
    width: 1250px;
  }
}

@media (max-width: 1171px) {
  .brows_list_content_page .container {
    width: 98%;
  }
}

@media (max-width: 767px) {
  .lots_slider_img,
  .list_view_img {
    width: unset;
    height: unset;
  }
  .lots_slider_img,
  .list_view_img,
  .lots_slider_img a {
    width: 100% !important;
  }
}

@media (max-width: 375px) {
  .brows_list_content_page .container {
    width: 100%;
  }
}
