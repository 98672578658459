.custom_top_banner_slider .carousel .carousel-caption .carosel-cat-name {
  color: #fff;
  font-size: 18px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.carosel-sub-sub-text {
  color: #fff;
  font-size: 22px;
  font-family: Noto Serif;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}
.carosel-btn-sec button {
  border-radius: 4px;
  border: none;
  background: #fff;
  color: #000;
  padding: 16px 37px;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0.32px;
  border: 1px solid #000;
}
figcaption {
  position: absolute;
  z-index: 20;
  top: 0;
  width: 100%;
  height: auto;
  display: block;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 46px;
}
.calander-banner-image-text-sec {
  margin-left: 12%;
}
.slick-slide .carosel-cat-name-new {
  color: #fff;
  font-family: Mercury Display A, Mercury Display B, MercuryDisplay, serif;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: none;
}
.slick-slide .carosel-cat-name-new img {
  aspect-ratio: auto;
  height: 20px;
  width: 16px;
  display: inline-block;
  object-fit: contain;
}
.main-sub-page-title {
  color: #fff;
  font-family: "MercuryDisplayBold,Times new roman";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}
.main-sub-page-sub-title {
  color: #fff;
  font-family: MercuryDisplayRegular, Times new roman;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (min-width: 1200px) {
  .slick-slide figure img {
    aspect-ratio: 1440 / 459;
    object-fit: cover;
    width: 100%;
  }
  .slick-slide figure {
    aspect-ratio: 1440 / 459;
    margin: 0;
  }
}
@media (min-width: 900px) {
  .heroLineClamp {
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .heroHoverText {
    color: #000 !important;
    background: #fff;
    width: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0 !important;
    top: -10px;
    padding: 3px 4px 0px 0px !important;
    z-index: 999;
    border-radius: 3px;
  }
}
@media (max-width: 600px) {
  .main-sub-page-sub-title {
    padding-right: 18px;
  }
}
