.followed_lots_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="followedlots"] {
  color: #000;
  font-weight: 600;
  background: #000;
  color: #fff;
}

.followed_lots_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="followedlots"]:hover {
  /* background: #fff; */
}
.my-followed-lots-sec-txt {
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 26px !important;
}
.account_all_right_section1 {
  padding: 0px !important;
}
.account_all_right_section2 {
  padding: 24px;
  padding-top: 0px;
}
.account_all_right_section2 .card {
  border: 0px;
}
.account_all_right_section2 .card-header button {
  padding: 12px !important;
  border-radius: 8px 8px 0px 0px;
  background-color: #fafafa !important;
  border-bottom: 0px !important;
}
.account_all_right_section2 .card-body {
  padding-left: 2rem !important;
}
.followed_lots_page_content.purchases_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="purchases"] {
  color: #000;
  /* font-weight: 400; */
  background: transparent;
}

.followed_lots_page_content.purchases_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="purchases"]:hover {
  background: #fff;
}

.purchases_no_activity .noResults {
  padding: 34px 0px 0px 25px;
  font-size: 20px;
}

@media (max-width: 991px) {
  .followed_lots_page_content .purchases_no_activity {
    margin: 0 -24px;
  }
}

@media (max-width: 767px) {
  .followed_lots_page_content .purchases_no_activity {
    margin: 0 -16px;
  }
}

@media (max-width: 575px) {
  .followed_lots_page_content .purchases_no_activity .card-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
