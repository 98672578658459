@font-face {
  font-family: "BentonSansSmall";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/BentonSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "BentonSansRegular";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/BentonSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "BentonSansLight";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/BentonSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "BentonSansMedium";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/BentonSans-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "BentonSansBold";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/BentonSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "MercuryDisplayBold";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/Mercury-Display-Bold.otf") format("woff2");
}

@font-face {
  font-family: "MercuryDisplayRegular";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/Mercury-Display-Roman.otf") format("woff2");
}

@font-face {
  font-family: "MercuryDisplayMedium";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/Mercury-Display-Semibold.otf") format("woff2");
}
