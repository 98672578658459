.custom_magage_profile_modal .modal-dialog {
  max-width: 650px;
}

.custom_magage_profile_modal .modal-content .form-control {
  height: 40px;
  font-size: 16px;
  color: #111111;
  font-weight: 500;
  font-family: "benton-sans", sans-serif;
  border-radius: 2px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
}

.custom_magage_profile_modal .modal-content .form-group {
  margin-bottom: 24px;
}
.profile_page_right_content .manage-profile-btn-sec:hover {
  border: 1px solid #000 !important;
}

.custom_magage_profile_modal .modal-content form .form-group .form-label {
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  margin: 0 0 8px;
  font-weight: 500;
  font-family: "benton-sans", sans-serif;
}

.custom_modal_box .modal-content .modal-body h5 {
  padding-bottom: 30px;
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  border-bottom: 1px solid #e9ecef;
}

.emptyLabel {
  margin-top: 27px !important;
}
.twoBox {
  margin-left: -79px !important;
}
.phoneCodeSelect {
  width: 67px !important;
  padding: 0 !important;
}
.phoneNumberStyles {
  width: 183px !important;
}
.cutom-mangage-profile-modal .modal-dialog {
  width: 630px;
  min-width: 630px;
}
.cutom-mangage-profile-modal .modal-body {
  padding: 24px;
}
.cutom-mangage-profile-modal .modal-body h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "BentonSansBold";
  font-size: 20px;
  font-weight: 500;
  line-height: 27.24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dbdbdb;
}
.cutom-mangage-profile-modal .modal-body h3 svg {
  cursor: pointer;
}
.edit-account-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.edit-profile-modal .close_modal_btn {
  font-family: "BentonSansMedium";
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  min-width: 185px;
  background: #ffffff;
  border-color: #111111;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.edit-profile-modal .close_modal_btn:hover {
  background: #000;
  color: #ffffff;
}
.edit-profile-modal .save_change_modal_btn {
  font-family: "BentonSansMedium";
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  min-width: 185px;
  background: #000;
  border-color: #111111;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
/* .edit-profile-modal .save_change_modal_btn:hover {
  color: #000;
  background: #ffffff;
} */
.edit-account-inputs label {
  font-family: "BentonSansRegular";
  font-size: 14px;
  font-weight: 400;
  line-height: 14.14px;
}
.edit-account-inputs input,
.edit-account-inputs select {
  padding: 15px 15px;
  min-height: 46px;
}
.edit-profile-modal {
  justify-content: end;
  gap: 16px;
}
.cutom-mangage-profile-modal
  .phone-control-fields-sec
  .phone-control-fields-sec-left {
  width: 50%;
}
.cutom-mangage-profile-modal
  .phone-control-fields-sec
  .phone-control-fields-sec-right {
  width: 80%;
}
.edit-title-col {
  padding: 0px;
}
.edit-title-col select {
  min-height: 46px;
}
@media (max-width: 767px) {
  .custom_magage_profile_modal .modal-content .form-control {
    border-radius: 4px;
  }
  .custom_magage_profile_modal .modal-content .form-group {
    margin-bottom: 20px;
  }
  .mobile-number-col {
    width: 140px;
  }
  .mobile-number-col-sec {
    width: calc(100% - 140px);
  }
  .twoBox {
    margin-left: -74px;
  }
  .cutom-mangage-profile-modal .modal-dialog {
    width: 95%;
    min-width: 95%;
  }
  .edit-profile-modal .close_modal_btn,
  .edit-profile-modal .save_change_modal_btn {
    min-width: auto;
    width: 100%;
  }
  .edit-account-inputs {
    grid-template-columns: repeat(1, 100%);
  }
}
