.email-preferences-page {
  padding: 23px;
  padding-top: 0px;
}
.email-preferences-page h3 {
  font-family: "benton-sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;

  color: #000000;
}
.email-preferences-page h4 {
  font-family: "benton-sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

.unsubscribe-box {
  margin-top: 20px;
}
.unsubscribe-text {
  font-weight: bold;
}
.email_preferences_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="emailpreferences"] {
  color: #000;
  font-weight: 400;
  background: transparent;
}
.email_preferences_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="emailpreferences"] {
  color: #000;
  font-weight: 600;
  background: #000;
  color: #fff;
  border-radius: 4px;
}

.email_preferences_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="emailpreferences"]:hover {
  /* background: #fff; */
}
.email-preferences-content-page {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  padding: 0px;
}
.your-email-box-sec {
  font-family: "benton-sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.6;
  /* identical to box height */
  padding: 10px 16px;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: 60%;
}
.email-sec-box-left {
  font-family: "benton-sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.your-email-sec-header-content {
  font-family: "benton-sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */
  margin-top: 40px;
  color: #000000;
}
.list-email-prefes-single-box {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  gap: 140px;
}
.list-email-prefes-single-box p {
  margin-bottom: 8px;
}

.email-sec-box-right input {
  height: 23px;
  width: 23px;
  accent-color: #000;
  cursor: pointer;
  border: 2px solid #000;
  appearance: none !important;
}
.email-sec-box-right input[type="checkbox"]:checked {
  appearance: auto !important;
}
.update-preferences-box button {
  width: 266px;
  height: 48px;
  left: 415px;
  bottom: 1037px;
  margin-top: 50px;
  background: #000000;
  border: 1px solid #111111;
  border-radius: 4px;
  font-family: "BentonSansMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;
}

@media (max-width: 767px) {
  .email-preferences-content-page {
    padding-right: 20px;
  }
  .your-email-box-sec {
    width: 80%;
  }
  .list-email-prefes-single-box {
    gap: 30px;
  }
}
